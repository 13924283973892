import React, { useState, useEffect } from "react";
import './endpoint.css';

export default function EndpointSetter({mobile}) {
    const handleDropdownChange = (e) => {
        console.log(e.target.value)
        localStorage.setItem("endpoint", e.target.value)
        window.location.reload();
      }
    return(
      <>
      {mobile ? 
        <select id="dropdown-mobile" onChange={handleDropdownChange}>
          <option value="" selected disabled hidden>Pick endpoint</option>
          <option value="https://chain.wax.io">Chain Wax</option>
          <option value="https://wax.cryptolions.io">Cryptolions</option>
          <option value="https://wax.eosdac.io">Eosdac</option>
          <option value="https://wax.pink.qq">Pinkqq</option>
          <option value="https://api.wax.bountyblok.io">Bountyblok</option>
          <option value="https://wax.eoseoul.io">EOSeoul</option>
          <option value="https://wax.eosusa.io">EOSUsa</option>
        </select>
        :
        <select id="dropdown" onChange={handleDropdownChange}>
        <option value="" selected disabled hidden>Pick endpoint</option>
        <option value="https://chain.wax.io">Chain Wax</option>
        <option value="https://wax.cryptolions.io">Cryptolions</option>
        <option value="https://wax.eosdac.io">Eosdac</option>
        <option value="https://wax.pink.qq">Pinkqq</option>
        <option value="https://api.wax.bountyblok.io">Bountyblok</option>
        <option value="https://wax.eoseoul.io">EOSeoul</option>
        <option value="https://wax.eosusa.io">EOSUsa</option>
      </select>}
      </>
    )
}

